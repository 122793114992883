import type {JTDSchemaType} from 'ajv/dist/jtd';
import type {ReportLayoutDocument} from '../../../../database';
import {userSettingTypeDef} from '../../../../database/collections/userSettings/userSettingTypeDef';
import type {JsonOf} from '../../../../interfaces/helpers';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/report-layouts';

export const method = HttpMethod.POST;

export type BodyType = Omit<ReportLayoutDocument, '_id'>;

export const bodyTypeDef: JTDSchemaType<JsonOf<BodyType>> = (() => {
    const {...optionalProperties} = userSettingTypeDef.optionalProperties;
    const {_id, ...properties} = userSettingTypeDef.properties;

    return {
        additionalProperties: true,
        optionalProperties,
        properties
    };
})();

export type ParamType = never;

export type QueryType = never;

export type OutputType = ReportLayoutDocument;
