import type {JTDSchemaType} from 'ajv/dist/jtd';
import type {PhotoDocument} from '../../../../database';
import {photoTypeDef} from '../../../../database/collections/photos/photoTypeDef';
import type {JsonOf} from '../../../../interfaces/helpers';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/photos';

export const method = HttpMethod.POST;

export type BodyType = Pick<PhotoDocument, 'description' | 'name' | 'offline_id'> & {
    content: string;
    contentEncoding: 'base64';
    contentType?: `image/${string}`;
};

export type ParamType = never;

export type QueryType = never;

export type OutputType = Omit<PhotoDocument, 's3_url' | 's3_bucket' | 's3_key' | 's3_region'>;

export const bodyTypeDef: JTDSchemaType<JsonOf<BodyType>> = {
    additionalProperties: true,
    optionalProperties: {
        contentType: photoTypeDef.properties.file_type as never,
        description: photoTypeDef.optionalProperties.description,
        name: photoTypeDef.optionalProperties.name,
        offline_id: photoTypeDef.optionalProperties.offline_id
    },
    properties: {
        content: {
            type: 'string'
        },
        contentEncoding: {
            enum: ['base64']
        }
    }
};

export const outputTypeDef: JTDSchemaType<JsonOf<OutputType>> = (() => {
    const {
        s3_url,
        s3_bucket,
        s3_key,
        s3_region,
        ...optionalProperties
    } = photoTypeDef.optionalProperties;

    return {
        ...photoTypeDef,
        optionalProperties
    };
})();
