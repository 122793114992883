import type {JTDSchemaType} from 'ajv/dist/jtd';
import type {InspectionTemplateDocument} from '../../../../database';
import {inspectionTemplateTypeDef} from '../../../../database/collections/checklists/inspectionTemplateTypeDef';
import type {JsonOf} from '../../../../interfaces/helpers';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/inspection-templates';

export const method = HttpMethod.POST;

export type BodyType = Omit<InspectionTemplateDocument,
    '_deleted' |
    '_id' |
    'category' |
    'classicTemplateId' |
    'collection' |
    'comment' |
    'company' |
    'createdAt' |
    'createdBy' |
    'creator' |
    'data' |
    'device' |
    'downloads' |
    'exclusive' |
    'expiresAt' |
    'fromExclusive' |
    'originalId' |
    'predefinedComments' |
    'private' |
    'rating' |
    'removed' |
    'removedAt' |
    'removedBy' |
    'targetId' |
    'touchedAt' |
    'touchedBy' |
    'updatedAt' |
    'updatedBy' |
    'userId'
>;

export type ParamType = never;

export type QueryType = never;

export type OutputType = InspectionTemplateDocument;

export const bodyTypeDef: JTDSchemaType<JsonOf<BodyType>> = (() => {
    const {
        expiresAt,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        touchedAt,
        removedAt,
        touchedBy,
        removedBy,
        removed,
        data,
        _deleted,
        category,
        classicTemplateId,
        collection,
        comment,
        company,
        creator,
        device,
        downloads,
        exclusive,
        fromExclusive,
        originalId,
        predefinedComments,
        private: _private,
        rating,
        targetId,
        userId,
        ...optionalProperties
    } = inspectionTemplateTypeDef.optionalProperties;

    return {
        additionalProperties: true,
        optionalProperties
    };
})();
