import type {JTDSchemaType} from 'ajv/dist/jtd';
import type {TaskDocument} from '../../../../database';
import {taskTypeDef} from '../../../../database/collections/tasks/taskTypeDef';
import type {JsonOf} from '../../../../interfaces/helpers';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/tasks';

export const method = HttpMethod.POST;

export type BodyType = Omit<JsonOf<TaskDocument>,
    '_id'
    | 'expiresAt'
    | 'createdAt'
    | 'createdBy'
    | 'updatedAt'
    | 'updatedBy'
    | 'touchedAt'
    | 'touchedBy'
    | 'removed'
    | '_deleted'
    | 'creatorName'
    | 'modified'
    | 'overdue'
    | 'planned'
    | 'rejected'
    | 'task'
    >;

export const bodyTypeDef: JTDSchemaType<JsonOf<BodyType>> = (() => {
    const {_id, ...properties} = taskTypeDef.properties;
    const {
        expiresAt,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        touchedAt,
        touchedBy,
        removed,
        _deleted,
        creatorName,
        modified,
        overdue,
        planned,
        rejected,
        task,
        ...optionalProperties
    } = taskTypeDef.optionalProperties;

    return {
        additionalProperties: true,
        optionalProperties,
        properties
    };
})();

export type ParamType = never;

export type QueryType = never;

export type OutputType = TaskDocument;
