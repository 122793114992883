import type {JTDSchemaType} from 'ajv/dist/jtd';
import type {CombinationOf} from '../../../api/helpers/shared';
import type {JsonOf} from '../../../interfaces/helpers';
import type {Removable} from './Removable';

export const removed: JTDSchemaType<JsonOf<CombinationOf<Removable>>>['properties']['removed'] = {
    metadata: {
        description: 'A single bit used in place of a boolean to allow indexing by IndexedDB; `0` if not removed and `1` if removed',
        maximum: 1,
        minimum: 0
    },
    type: 'uint8'
};

export const removedAt: JTDSchemaType<JsonOf<CombinationOf<Removable>>>['optionalProperties']['removedAt'] = {
    metadata: {
        description: 'When `removed`, stores the time in milliseconds since epoch of when this document was removed',
        minimum: 0
    },
    type: 'uint32'
};

export const removedBy: JTDSchemaType<JsonOf<CombinationOf<Removable>>>['optionalProperties']['removedBy'] = {
    metadata: {
        description: 'When `removed`, stores the `_id` of the team member who has removed this document',
        regex: (/^[a-z0-9]{24}$/).toString()
    },
    type: 'string'
};

export const removableTypeDef: JTDSchemaType<JsonOf<CombinationOf<Removable>>> = {
    optionalProperties: {
        removedAt,
        removedBy
    },
    properties: {
        removed
    }
};

