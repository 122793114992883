import type {JTDSchemaType} from 'ajv/dist/jtd';
import type {JsonOf} from '../../../interfaces/helpers';
import type {UserDocument} from './UserDocument';

export const userTypeDef: JTDSchemaType<JsonOf<UserDocument>> = {
    additionalProperties: false,
    optionalProperties: {
        _deleted: {
            type: 'boolean'
        },
        _ip: {
            type: 'string'
        },
        active: {
            type: 'boolean'
        },
        activeCampaignId: {
            type: 'string'
        },
        appleId: {
            type: 'string'
        },
        blocked: {
            type: 'boolean'
        },
        classicUserId: {
            type: 'string'
        },
        context: {
            additionalProperties: false,
            properties: {
                entityId: {
                    type: 'string'
                }
            }
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {},
        createdOn: {
            type: 'string'
        },
        expiresAt: {
            type: 'timestamp'
        },
        friendlyname: {
            nullable: true
        },
        githubId: {
            type: 'string'
        },
        googleId: {
            type: 'string'
        },
        invitedAt: {
            type: 'float64'
        },
        invitedBy: {
            type: 'string'
        },
        isReseller: {
            type: 'boolean'
        },
        isSuperAdmin: {
            type: 'boolean'
        },
        lastLogin: {
            type: 'float64'
        },
        mail: {
            additionalProperties: false,
            optionalProperties: {
                day2: {
                    type: 'boolean'
                },
                day7: {
                    type: 'boolean'
                },
                day15: {
                    type: 'boolean'
                },
                day30: {
                    type: 'boolean'
                }
            },
            properties: {
                day0: {
                    type: 'boolean'
                }
            }
        },
        microsoftId: {
            type: 'string'
        },
        myEmail: {
            type: 'string'
        },
        name: {
            type: 'string'
        },
        needsPassword: {
            nullable: true,
            type: 'boolean'
        },
        offline_id: {
            type: 'string'
        },
        password: {
            type: 'string'
        },
        photo: {} as never,
        photo_id: {
            type: 'string'
        },
        profile: {
            additionalProperties: false,
            properties: {
                _id: {
                    type: 'string'
                },
                created: {
                    type: 'float64'
                },
                demo: {
                    type: 'boolean'
                },
                email: {
                    type: 'string'
                },
                name: {
                    type: 'string'
                },
                updated: {
                    type: 'float64'
                },
                userId: {
                    type: 'string'
                }
            }
        },
        removed: {
            type: 'float64'
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        salesGotInfo: {
            type: 'boolean'
        },
        tags: {
            elements: {
                type: 'string'
            }
        },
        token: {
            type: 'string'
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        undefined: {
            nullable: true
        },
        updated: {
            type: 'float64'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {},
        verificationKey: {
            type: 'string'
        },
        verified: {
            type: 'boolean'
        },
        verifiedAt: {
            type: 'float64'
        },
        verifiedBy: {
            type: 'string'
        },
        version: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        created: {
            type: 'float64'
        },
        email: {
            type: 'string'
        },
        locations: {
            elements: {
                type: 'string'
            }
        },
        roles: {
            elements: {
                type: 'float64'
            }
        },
        templates: {
            elements: {
                type: 'string'
            }
        }
    }
};
