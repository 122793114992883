import type {Markdown} from '../docx/markdown/markdown';
import type {HexColor, Padding} from './ReportData';

export type ReportDataConfig = {
    answers: {
        atpWidthPx: number;
        atpHeightPx: number;
        locationWidthPx: number;
        locationHeightPx: number;
        paddingMm: Padding;
        ratingWidthPx: number;
        ratingHeightPx: number;
        signatureWidthPx: number;
        signatureHeightPx: number;
        textColorHex: HexColor;
        textSizePt: number;
    };
    answersAlt: {
        paddingMm: Padding;
    };
    comments: {
        icon: Markdown;
        iconPaddingMm: Padding;
        iconWidthPx: number;
        iconHeightPx: number;
        textColorHex: HexColor;
        textPaddingMm: Padding;
        textSizePt: number;
    };
    correctiveActions: {
        availableTextLines: number;
        columnLabels: [Markdown, Markdown, Markdown, Markdown];
        columnWidthsPct: [number, number, number, number];
        headingColorHex: HexColor;
        headingPaddingMm: Padding;
        headingSizePt: number;
        style: 'table' | 'blank';
        textColorHex: HexColor;
        textPaddingMm: Padding;
        textSizePt: number;
    };
    criteria: {
        paddingMm: Padding;
        textColorHex: HexColor;
        textSizePt: number;
    };
    footer: {
        textColorHex: HexColor;
        textSizePt: number;
    };
    frontpage: {
        paddingMm: Padding;
        includeFrontpage: boolean;
        inspectionNameColorHex: HexColor;
        inspectionNamePaddingMm: Padding;
        inspectionNameSizePt: number;
        inspectionTemplateNameColorHex: HexColor;
        inspectionTemplateNamePaddingMm: Padding;
        inspectionTemplateNameSizePt: number;
        textColorHex: HexColor;
        textPaddingMm: Padding;
        textSizePt: number;
        titleColorHex: HexColor;
        titlePaddingMm: Padding;
        titleSizePt: number;
    };
    header: {
        logoWidthPx: number;
        logoHeightPx: number;
    };
    indicators: {
        iconPaddingMm: Padding;
        iconWidthPx: number;
        iconHeightPx: number;
    };
    page: {
        paddingMm: Padding;
    };
    photos: {
        paddingMm: Padding;
        photoWidthPx: number;
        photoHeightPx: number;
        textColorHex: HexColor;
        textSizePt: number;
    };
    preface: {
        headingColorHex: HexColor;
        headingPaddingMm: Padding;
        headingSizePt: number;
        resultScoreColorHex: HexColor;
        textColorHex: HexColor;
        textPaddingMm: Padding;
        textSizePt: number;
    };
    questions: {
        paddingMm: Padding;
        textColorHex: HexColor;
        textSizePt: number;
    };
    sections: {
        headingColorHex: HexColor;
        headingPaddingMm: Padding;
        headingRepeat: boolean;
        headingSizePt: number;
        scorePaddingMm: Padding;
        scoreSizePt: number;
    };
    spacer: {
        availableTextLines: number;
        paddingMm: Padding;
    };
    tasks: {
        paddingMm: Padding;
        textColorHex: HexColor;
        textSizePt: number;
    };
};

export const DEFAULT_REPORT_DATA_CONFIG: ReportDataConfig = {
    answers: {
        atpWidthPx: 18,
        atpHeightPx: Number.MAX_SAFE_INTEGER,
        locationWidthPx: 400,
        locationHeightPx: Number.MAX_SAFE_INTEGER,
        paddingMm: [1, 1, 1, 1],
        ratingWidthPx: 36,
        ratingHeightPx: Number.MAX_SAFE_INTEGER,
        signatureWidthPx: 200,
        signatureHeightPx: Number.MAX_SAFE_INTEGER,
        textColorHex: '#000000',
        textSizePt: 11
    },
    answersAlt: {
        paddingMm: [1, 1, 1, 1]
    },
    comments: {
        icon: '![comment](deprecated:comment)',
        iconPaddingMm: [1, 1, 1, 1],
        iconWidthPx: 20,
        iconHeightPx: Number.MAX_SAFE_INTEGER,
        textColorHex: '#333333',
        textPaddingMm: [1, 1, 1, 1],
        textSizePt: 10
    },
    correctiveActions: {
        availableTextLines: 3,
        columnLabels: ['Date', 'Corrective action', 'Date solved', 'Signature'],
        columnWidthsPct: [15, 55, 15, 15],
        headingColorHex: '#000000',
        headingPaddingMm: [1, 1, 1, 1],
        headingSizePt: 9,
        style: 'table',
        textColorHex: '#000000',
        textPaddingMm: [1, 1, 1, 1],
        textSizePt: 9
    },
    criteria: {
        paddingMm: [1, 1, 1, 1],
        textColorHex: '#000000',
        textSizePt: 9
    },
    footer: {
        textColorHex: '#000000',
        textSizePt: 10
    },
    frontpage: {
        paddingMm: [70, 25, 20, 25],
        includeFrontpage: true,
        inspectionNameColorHex: '#123672',
        inspectionNamePaddingMm: [1, 1, 1, 1],
        inspectionNameSizePt: 20,
        inspectionTemplateNameColorHex: '#000000',
        inspectionTemplateNamePaddingMm: [1, 1, 20, 1],
        inspectionTemplateNameSizePt: 14,
        textColorHex: '#000000',
        textPaddingMm: [1, 1, 1, 1],
        textSizePt: 10,
        titleColorHex: '#000000',
        titlePaddingMm: [1, 1, 1, 1],
        titleSizePt: 20
    },
    header: {
        logoWidthPx: 400,
        logoHeightPx: 132
    },
    indicators: {
        iconPaddingMm: [1, 1, 1, 1],
        iconWidthPx: 26,
        iconHeightPx: Number.MAX_SAFE_INTEGER
    },
    page: {
        paddingMm: [25, 10, 10, 25]
    },
    photos: {
        paddingMm: [1, 1, 1, 1],
        photoWidthPx: 305,
        photoHeightPx: 305 / 3 * 4,
        textColorHex: '#000000',
        textSizePt: 1
    },
    preface: {
        headingColorHex: '#123672',
        headingPaddingMm: [1, 1, 1, 1],
        headingSizePt: 14,
        resultScoreColorHex: '#000000',
        textColorHex: '#000000',
        textPaddingMm: [1, 1, 1, 1],
        textSizePt: 9
    },
    questions: {
        paddingMm: [1, 1, 1, 1],
        textColorHex: '#000000',
        textSizePt: 11
    },
    sections: {
        headingColorHex: '#123672',
        headingPaddingMm: [1, 1, 1, 1],
        headingRepeat: true,
        headingSizePt: 14,
        scorePaddingMm: [1, 1, 1, 1],
        scoreSizePt: 14
    },
    spacer: {
        availableTextLines: 1,
        paddingMm: [1, 1, 1, 1]
    },
    tasks: {
        paddingMm: [1, 1, 1, 1],
        textColorHex: '#000000',
        textSizePt: 7
    }
};
