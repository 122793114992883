import type {JTDSchemaType} from 'ajv/dist/jtd';
import type {JsonOf} from '../../../interfaces/helpers';
import type {ChartDocument} from './ChartDocument';

export const chartTypeDef: JTDSchemaType<JsonOf<ChartDocument>> = {
    additionalProperties: false,
    optionalProperties: {
        expiresAt: {
            type: 'string'
        },
        offline_id: {
            type: 'string'
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        },
        xRangeEnd: {
            type: 'float64'
        },
        xRangeStart: {
            type: 'float64'
        },
        yRangeEnd: {
            type: 'float64'
        },
        yRangeStart: {
            type: 'float64'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {
            type: 'string'
        },
        entity_id: {
            type: 'string'
        },
        groupBy: {
            enum: ['date', 'template_id', 'location_id', 'assignedTo']
        },
        name: {
            type: 'string'
        },
        orientation: {
            enum: ['horizontal', 'vertical']
        },
        removed: {
            type: 'int8'
        },
        showGrid: {
            type: 'boolean'
        },
        showLegend: {
            type: 'boolean'
        },
        showTooltip: {
            type: 'boolean'
        },
        showXAxis: {
            type: 'boolean'
        },
        showYAxis: {
            type: 'boolean'
        },
        showZoom: {
            type: 'boolean'
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        valueDataKey: {
            type: 'string'
        }
    }
};
