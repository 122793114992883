import type {FunctionComponent} from 'react';
import React, {useEffect} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import {getSessions} from '../../../../api/endpoints/sessies/get/frontend';
import {getUser} from '../../../../api/endpoints/users/[userId]/get/frontend';
import {Image} from '../../../../react/components/Image';
import {useCurrentUser} from '../../../../react/context';
import {__} from '../../../../translations';
import {Content} from '../../../components/layout/Content';
import {Page} from '../../../components/layout/Page';
import loadingImage from '../../../icons/loading-icon.gif';
import {setCookie} from '../../../utilities/storage';

export const SingleSignOnProviderIdPage: FunctionComponent<RouteComponentProps<{
    providerId: string;
}>> = (props) => {
    const {history, match} = props;
    document.title = `Checkbuster | ${__('Single Sign-On')}`;
    const {currentUser: authenticatedUser} = useCurrentUser();

    useEffect(() => setCookie('authpage', 'sso'), []);

    useEffect(() => {
        if (authenticatedUser) {
            history.replace(authenticatedUser.needsPassword ? `/team-members/${authenticatedUser._id}` : '/inspections');
        }
    }, [authenticatedUser]);

    useEffect(() => {
        getSessions({current: true})
            .then(async ({json: sessions}) => {
                const userId = sessions?.[0]?.user_id;

                if (!userId) {
                    return history.replace('/single-sign-on');
                }

                const {response, json: user} = await getUser({userId});

                !response.ok && history.replace('/single-sign-on');

                if (match.params.providerId === 'microsoft' && !user?.microsoftId) {
                    history.replace('/single-sign-on#link-microsoft');
                }
            })
            .catch(() => history.replace('/single-sign-on'));
    }, []);

    return (
        <Page name="sso-providerid" disableLogin>
            <Content>
                <div className="loading-screen">
                    <Image className="loading-screen__logo" src={loadingImage} alt={__('Loading indicator')}/>
                </div>
            </Content>
        </Page>
    );
};
