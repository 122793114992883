import type {ProductDocument} from '../../../../database';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/products';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = never;

export type QueryType = {

    /**
     * Pick results created after the specified timestamp.
     */
    createdAfter?: number;

    /**
     * Pick results created before the specified timestamp.
     */
    createdBefore?: number;

    /**
     * Pick results that have been activated.
     */
    isActive?: boolean;

    /**
     * Pick results that match the specified name;
     * case-insensitive.
     */
    name?: string;

    /**
     * Sorts results in either ascending (+) or descending order (-) based
     * on the specified property name.
     */
    sort?: `${'+' | '-'}${'createdAt' | 'name'}`;

};

export type OutputType = Omit<ProductDocument, 'stripeId'>[];
