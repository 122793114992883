import {dexie} from '../../../../dexie';
import {fetchApi} from '../../../helpers/frontend';
import {HttpStatusCode} from '../../../HttpStatusCode';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {path, method} from './shared';

export * from './shared';

export const getProducts = async (queryParams?: QueryType) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        method,
        path,
        queryParams
    });

    const {response, json} = result;
    if (response.ok && response.status !== HttpStatusCode.NOT_MODIFIED && json) {
        await dexie.products.bulkPut(json);
    }

    return result;
};
