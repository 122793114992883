import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getInspectionTemplates} from '../../api/endpoints/inspection-templates/get/frontend';
import type {InspectionTemplateDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import {createJsonPointer} from '../../types/JsonPointer';
import type {MongoDbObjectId, OfflineObjectId} from '../../types/MongoDb';
import {escapeRegex} from '../../util/strings';
import {useCurrentSession} from '../context';
import {sortAlphabetically, useDexieResources} from './useDexieResources';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useInspectionTemplates = (filter: {
    name?: string;
    inspectionTemplateIds?: string[];
    createdAfter?: number;
    createdBefore?: number;
    updatedAfter?: number;
    updatedBefore?: number;
    createdByArray?: string[];
    updatedByArray?: string[];
} = {}): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    inspectionTemplates: JsonOf<InspectionTemplateDocument>[];
    updateInspectionTemplate: (inspectionTemplateId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => Promise<boolean>;
    createInspectionTemplate: (inspectionTemplate: Partial<JsonOf<InspectionTemplateDocument>>) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId, entity_id: entityId} = currentSession ?? {};
    const searchName = filter.name && escapeRegex(filter.name);
    const {inspectionTemplateIds, createdBefore, createdAfter, updatedBefore, updatedAfter, createdByArray, updatedByArray} = filter;

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {dexieActive, resources: inspectionTemplates} = useDexieResources<InspectionTemplateDocument>({
        filter({_deleted, name, createdAt, created, updatedAt, creator: createdBy, updatedBy}: JsonOf<InspectionTemplateDocument>) {
            if (_deleted) {
                return false;
            }

            if (createdBefore !== undefined && createdBefore < (createdAt ?? created ?? 0)) {
                return false;
            }

            if (createdAfter !== undefined && createdAfter > (createdAt ?? created ?? 0)) {
                return false;
            }

            if (updatedBefore !== undefined && (!updatedAt || updatedBefore < updatedAt)) {
                return false;
            }

            if (updatedAfter !== undefined && (!updatedAt || updatedAfter > updatedAt)) {
                return false;
            }

            if (createdByArray?.length && (!createdBy || !createdByArray.includes(createdBy))) {
                return false;
            }

            if (updatedByArray?.length && (!updatedBy || !updatedByArray.includes(updatedBy))) {
                return false;
            }

            if (searchName) {
                return !!name && new RegExp(searchName, 'i').test(name);
            }

            return true;
        },
        sort: sortAlphabetically,
        table: dexie.inspectionTemplates,
        async query() {
            if (!entityId) {
                return false;
            }

            return inspectionTemplateIds?.length
                ? dexie.inspectionTemplates
                    .where('_id').anyOf(inspectionTemplateIds)
                    .toArray()
                : dexie.inspectionTemplates
                    .where('context.entityId').equals(entityId)
                    .toArray();
        }
    }, [searchName, entityId, inspectionTemplateIds, filter.name, createdBefore, createdAfter, updatedBefore, updatedAfter, createdByArray, updatedByArray]);

    const {ready} = useReadyState({dexieActive, fetchActive});

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!userId) {
            return;
        }

        setFetchActive(true);
        getInspectionTemplates({
            name: filter.name
        })
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, visibilityState, filter.name]);

    return {
        ready,
        dexieActive,
        fetchActive,
        inspectionTemplates,
        updateInspectionTemplate: async (inspectionTemplateId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => {
            if (!userId || !entityId || !inspectionTemplateId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'inspectionTemplates',
                    resourceId: inspectionTemplateId,
                    userId,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        },
        createInspectionTemplate: async (inspectionTemplate: Partial<JsonOf<InspectionTemplateDocument>> = {}) => {
            if (!userId || !entityId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'create',
                    resourceTableName: 'inspectionTemplates',
                    resourceId: inspectionTemplate.offline_id!,
                    userId,
                    entityId,
                    body: {
                        reportTemplateIds: [],
                        tags: [],
                        ...inspectionTemplate,
                        activated: true
                    }
                });

                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'users',
                    resourceId: userId,
                    userId,
                    entityId,
                    patch: [{
                        op: 'add',
                        path: createJsonPointer('templates', '-'),
                        value: inspectionTemplate.offline_id
                    }]
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
