import type {PriceDocument} from '../../../../database';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/prices';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = never;

export type QueryType = {

    /**
     * Pick results created after the specified timestamp.
     */
    createdAfter?: number;

    /**
     * Pick results created before the specified timestamp.
     */
    createdBefore?: number;

    /**
     * Pick results that have been activated.
     */
    isActive?: boolean;

    /**
     * Pick results that are set as default.
     */
    isDefault?: boolean;

    /**
     * Pick results connected to any of the specified product ids.
     */
    productIdAny?: string[];

    /**
     * Sorts results in either ascending (+) or descending order (-) based
     * on the specified property name.
     */
    sort?: `${'+' | '-'}${'createdAt'}`;
};

export type OutputType = Omit<PriceDocument, 'stripeId'>[];
