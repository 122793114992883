import type {JTDSchemaType} from 'ajv/dist/jtd';
import type {JsonOf} from '../../../interfaces/helpers';
import {userTypeDef} from './userTypeDef';
import type {UserWithContextDocument} from './UserWithContextDocument';

const {...properties} = userTypeDef.properties;
const {context, token, ...optionalProperties} = userTypeDef.optionalProperties;

export const userWithContextTypeDef: JTDSchemaType<JsonOf<UserWithContextDocument>> = {
    additionalProperties: false,
    optionalProperties,
    properties: {
        ...properties,
        context
    }
};
