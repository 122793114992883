import type {JTDSchemaType} from 'ajv/dist/jtd';
import type {JsonOf} from '../../../interfaces/helpers';
import type {TaskDocument} from './TaskDocument';

export const taskTypeDef: JTDSchemaType<JsonOf<TaskDocument>> = {
    additionalProperties: false,
    optionalProperties: {
        _deleted: {
            type: 'boolean'
        },
        closed: {
            type: 'int8'
        },
        closedAt: {
            type: 'float64'
        },
        closedBy: {
            type: 'string'
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {
            type: 'string'
        },
        creatorName: {
            type: 'string'
        },
        description: {
            type: 'string'
        },
        dueDate: {
            nullable: true,
            type: 'float64'
        },
        expiresAt: {
            type: 'timestamp'
        },
        modified: {
            type: 'float64'
        },
        offline_id: {
            type: 'string'
        },
        overdue: {
            type: 'boolean'
        },
        planned: {
            type: 'boolean'
        },
        rejected: {
            type: 'boolean'
        },
        removed: {
            type: 'float64'
        },
        tags: {
            elements: {
                type: 'string'
            }
        },
        task: {
            type: 'float64'
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        updated: {
            type: 'float64'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        assignedToExternalUser: {
            elements: {
                type: 'string'
            }
        },
        assignedToUser: {
            elements: {
                type: 'string'
            }
        },
        context: {
            additionalProperties: false,
            properties: {
                entityId: {
                    type: 'string'
                }
            }
        },
        created: {
            type: 'float64'
        },
        creator: {
            type: 'string'
        },
        meta: {
            additionalProperties: false,
            optionalProperties: {
                incidentId: {
                    type: 'string'
                },
                inspectionId: {
                    type: 'string'
                },
                inspectionItemId: {} as never,
                inspectionItemIndex: {
                    type: 'float64'
                },
                inspectionItemName: {
                    type: 'string'
                },
                inspectionName: {
                    type: 'string'
                },
                locationId: {
                    type: 'string'
                },
                locationName: {
                    type: 'string'
                },
                sectionIndex: {
                    type: 'string'
                },
                sectionName: {
                    type: 'string'
                },
                templateId: {
                    type: 'string'
                },
                templateName: {
                    type: 'string'
                }
            },
            properties: {
                comments: {
                    elements: {
                        additionalProperties: false,
                        optionalProperties: {
                            email: {
                                type: 'string'
                            },
                            files: {
                                elements: {} as never
                            },
                            id: {
                                type: 'float64'
                            },
                            state: {
                                type: 'string'
                            },
                            userName: {
                                type: 'string'
                            }
                        },
                        properties: {
                            date: {
                                type: 'float64'
                            },
                            text: {
                                type: 'string'
                            },
                            user: {
                                type: 'string'
                            }
                        }
                    }
                },
                files: {
                    elements: {} as never
                }
            }
        },
        state: {
            enum: ['open', 'done', 'submitted']
        } as never,
        title: {
            type: 'string'
        }
    }
};

