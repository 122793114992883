import type {FunctionComponent, ReactElement} from 'react';
import React, {createContext, useContext, useReducer, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {delay} from '../../utilities/timing';

type MenuData = {
    isSidebarOpen: boolean;
};
type MenuUpdater = (newState: Partial<MenuData>) => void;

const MenuDataContext = createContext<MenuData>(undefined as unknown as MenuData);
const MenuUpdaterContext = createContext<MenuUpdater>(undefined as unknown as MenuUpdater);

const reducer = (data: MenuData, newData: Partial<MenuData>): MenuData => {
    for (const property in newData) {
        if (data[property] !== newData[property]) {
            return {
                ...data,
                ...newData
            };
        }
    }

    return data;
};

export const MenuProvider: FunctionComponent = ({children}): ReactElement => {
    const [state, dispatch] = useReducer(reducer, {
        isSidebarOpen: false
    });
    const history = useHistory();

    useEffect(() => history.listen(() => {
        void delay(200, () => dispatch({isSidebarOpen: false}));
    }), [history]);

    return (
        <MenuDataContext.Provider value={state}>
            <MenuUpdaterContext.Provider value={dispatch}>
                {children}
            </MenuUpdaterContext.Provider>
        </MenuDataContext.Provider>
    );
};

export const useMenuData = (): MenuData => useContext(MenuDataContext);

export const useMenuUpdater = (): MenuUpdater => useContext(MenuUpdaterContext);

export const useMenu = (): [MenuData, MenuUpdater] => [useMenuData(), useMenuUpdater()];
