import type {Table} from 'dexie';
import {useLiveQuery} from 'dexie-react-hooks';
import {useEffect, useMemo, useRef, useState} from 'react';
import type {Identifiable} from '../../database/interfaces';
import {dexie} from '../../dexie';
import {addEventListener} from '../../dexie/changeListener';
import type {QueueCreateEntry, QueueUpdateEntry} from '../../dexie/dexie';
import type {JsonOf} from '../../interfaces/helpers';
import {hasProperty} from '../../types';
import {applyJsonPatch} from '../../types/JsonPatch';
import {emptyArray} from '../../util/arrays';

/**
 * @deprecated import from src/util/arrays instead
 */
export {sortAlphabetically} from '../../util/arrays';

const filterDeleted = (document: unknown) => !hasProperty(document, '_deleted') || document._deleted !== true;

export const sortChronologically = <Document extends {
    created?: number;
    updated?: number;
}>(a: Document, b: Document): number => {
    const timestampA = a.created;
    const timestampB = b.created;

    if (typeof timestampA !== 'number') {
        return -1;
    }

    if (typeof timestampB !== 'number') {
        return 1;
    }

    return timestampB - timestampA;
};

export const sortStably = <Document extends {
    _id?: string;
}>(a: Document, b: Document): number => {
    const timestampA = a._id && parseInt(a._id.substring(0, 8), 16);
    const timestampB = b._id && parseInt(b._id.substring(0, 8), 16);

    if (typeof timestampA !== 'number') {
        return -1;
    }

    if (typeof timestampB !== 'number') {
        return 1;
    }

    return timestampB - timestampA;
};

export const useDexieResources = <Document extends {
    name?: string | null;
    created?: number;
    updated?: number;
} & Identifiable, PrimaryKey extends string | number = string>({table, query, sort, filter = filterDeleted}: {
    filter?: (document: JsonOf<Document>) => boolean;
    sort: (documentA: JsonOf<Document>, documentB: JsonOf<Document>) => number;
    table: Table<JsonOf<Document>, PrimaryKey>;
    query: () => Promise<JsonOf<Document>[] | boolean | string | number | undefined | null>;
}, dependencies: unknown[]): {
    resources: JsonOf<Document>[];
    dexieActive: boolean;
} => {
    const [resources, setResources] = useState<JsonOf<Document>[]>([]);

    const creates = useLiveQuery<QueueCreateEntry[], QueueCreateEntry[]>(async () => {
        const results = await dexie.queue
            .where(['resourceTableName', 'action'])
            .equals([table.name, 'create'])
            .toArray();

        return results.length
            ? results as QueueCreateEntry[]
            : emptyArray;
    }, [table.name], emptyArray);

    const updates = useLiveQuery<QueueUpdateEntry[], QueueUpdateEntry[]>(async () => {
        const results = await dexie.queue
            .where(['resourceTableName', 'action'])
            .equals([table.name, 'update'])
            .toArray();

        return results.length
            ? results as QueueUpdateEntry[]
            : emptyArray;
    }, [table.name], emptyArray);

    const [dexieActive, setQueryActive] = useState(true);
    const ref = useRef(resources);
    ref.current = resources;

    const patchedResources = useMemo<JsonOf<Document>[]>(() => {
        console.log(`[useDexieResources] ${table.name} resources=${resources.length} creates=${creates.length} updates=${updates.length}`, {
            resources,
            creates,
            updates
        });

        const patched = [
            ...resources,
            ...creates.map(({body}) => body as JsonOf<Document>)
        ];

        for (const update of updates) {
            const {resourceId, patch} = update;

            const index = patched.findIndex(({_id, offline_id}) => resourceId === _id || (offline_id && resourceId === offline_id));
            if (index >= 0) {
                patched[index] = applyJsonPatch<JsonOf<Document>>(patched[index], patch.filter(({op}) => op !== 'test'));
            }
        }

        return patched.filter(filter).sort(sort);
    }, [resources, creates, updates]);

    useEffect(() => {
        query().then((result) => {
            if (!Array.isArray(result)) {
                return;
            }

            setResources(result.filter(filter));
            setQueryActive(false);
        });
    }, dependencies);

    useEffect(() => addEventListener('create', (event) => {
        const {table: tableName, obj: record} = event.detail;

        if (tableName !== table.name || !hasProperty(record, '_id')) {
            return;
        }

        ref.current.push(record);
        setResources([...ref.current]);
    }), []);

    useEffect(() => addEventListener('update', (event) => {
        const {table: tableName, obj: record} = event.detail;

        if (tableName !== table.name || !hasProperty(record, '_id')) {
            return;
        }

        const index = resources.findIndex(({_id}) => record._id === _id);

        if (index >= 0) {
            const newResources = [...resources];
            newResources.splice(index, 1, record);
            setResources(newResources);
        } else {
            setResources([...resources, record as JsonOf<Document>]);
        }
    }), [resources]);

    useEffect(() => addEventListener('delete', (event) => {
        const {table: tableName, oldObj: record} = event.detail;

        if (tableName !== table.name || !hasProperty(record, '_id')) {
            return;
        }

        const index = resources.findIndex(({_id}) => record._id === _id);

        if (index >= 0) {
            const newResources = [...resources];
            newResources.splice(index, 1);
            setResources(newResources);
        }
    }), [resources]);

    return {dexieActive, resources: patchedResources};
};
