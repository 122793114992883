import {hasProperty} from './helpers';

export const isItemV1 = <T>(object: unknown, key: `is${Uppercase<string>}`): object is T => hasProperty(object, 'spec') && hasProperty(object.spec, key) && object.spec[key] === true;

export const clearItem = (item) => {
    item.input.state = 'empty';
    item.input.photos = [];
    item.input.taskIds = [];
    item.isFavourite = false;
    delete item.updated;
    delete item.updatedAt;
    delete item.updatedBy;

    if (item.input.value !== undefined && item.type !== 'location') {
        item.input.value = '';
    }

    if (item.input.value !== undefined && item.type === 'location') {
        item.input.value = null;
    }

    if (item.input.options !== undefined) {
        item.input.options = item.input.options.map(({text, value}) => ({text, value, selected: false}));
    }

    if (item.input.image !== undefined) {
        item.input.image = '';
    }
};
