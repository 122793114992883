import {getPrices} from '../api/endpoints/prices/get/frontend';
import {getProducts} from '../api/endpoints/products/get/frontend';
import type {PriceDocument, ProductDocument} from '../database';
import {JsonOf} from "../interfaces/helpers";

export const noop = () => undefined;

export const getDefaultUpgradeProduct = async (): Promise<JsonOf<Omit<ProductDocument, 'stripeId'>> | undefined> => {
    const {json: products} = await getProducts({name: 'Premium'});
    return products?.[0];
};

export const getProductPrices = async (productId: string): Promise< JsonOf<Omit<PriceDocument, 'stripeId'>>[] | null> => {
    const {json: prices} = await getPrices({productIdAny: [productId]});
    return prices;
};

export const textTrim = (value: string) : string => value.replace(/\s+/g, ' ').trim();

export const isNumeric = (value: string) : boolean => (/^-{0,1}\d*\.{0,1}\d+$/).test(value);
