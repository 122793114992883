import type {FunctionComponent} from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import './LinkButton.scss';

export const LinkButton: FunctionComponent<{
    id?: string;
    to?: string;
    small?: boolean;
    replace?: boolean;
    color?: 'red' | 'green' | 'blue' | 'white';
    className?: string;
    onClick?: any;
    disabled?: boolean;
}> = ({className, color = 'blue', children, to, small, onClick, ...rest}) => {
    const classNames = [
        'link-button',
        `link-button--color-${color}`,
        `link-button--small-${small ? 'yes' : 'no'}`,
        className
    ].join(' ').trim();

    const handleOnClick = (event) => {
        if (!to) {
            event.preventDefault();
        }
        onClick && onClick(event);
    };

    return (
        <Link
            to={to!}
            className={classNames}
            onClick={handleOnClick}
            {...rest}
        >
            {children}
        </Link>
    );
};
